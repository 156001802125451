import '@babel/polyfill';
import svg4everybody from 'svg4everybody';
import $ from 'jquery';
import 'slick-carousel';
window.jQuery = $;
require('@fancyapps/fancybox/');
require('./jquery.customSelect.min');
require("jquery-mousewheel");
require('malihu-custom-scrollbar-plugin');

$(window).on('load', function() {
    // PRELOADER
    $('.preloader').removeClass('preloader_active');
    $('html').css('overflow', 'auto');
});

$(document).ready(function() {
    // HEADER STICKY
    $(window).on('scroll', function() {
        if ($(this).scrollTop()) {
            $('.header').addClass('header__stuck');
        } else {
            $('.header').removeClass('header__stuck');
        }
    }).scroll();

    // MENU BURGER
    $('.header__trigger').on('click', function() {
        $(this).toggleClass('header__trigger_active');
        $('.header__nav').slideToggle('', function() {
            if ($(this).attr('style') === 'display: none;') {
                $(this).attr('style', '')
            }
        });
    });

    // SCROLL NAV
    $(window).on('scroll', function() {
        const scrollTop = $(this).scrollTop();
        const offset = $(this).height() / 3;
        $('.js-scroll-section').each(function(i) {
            if ($(this).position().top <= scrollTop + offset) {
                $('.header__nav .menu__item').removeClass('menu__item_active').eq(i).addClass('menu__item_active');
            }
        });
    }).scroll();
    $('.header__nav .menu__item').on('click', function() {
        if ($(window).width() < 1024) {
            $('.header__trigger').trigger('click');
        }
    });

    // SCROLL TO
    $('.js-scroll-to').on('click', function(e) {
        e.preventDefault();
        let targetId = $(this).attr('href');
        let header_height = $('.header').height();
        if ($(window).width() < 1024) {
            $('html, body').animate({ scrollTop: $(targetId).offset().top - header_height }, 560);
        } else {
            $('html, body').animate({ scrollTop: $(targetId).offset().top }, 560);
        }
    });



    // SCHEME
    $('.js-scheme-anchor').on('click', function(e) {
        e.preventDefault();
        const targetId = $(this).attr('href');
        const active = $(this).hasClass('scheme__badge_active');
        $(this).toggleClass('scheme__badge_active');
        $('.js-scheme-anchor').not($(this)).removeClass('scheme__badge_active');
        $('.scheme__tooltip').removeClass('scheme__tooltip_active');
        if (!active) {
            $(targetId).addClass('scheme__tooltip_active');
        }
    });
    $('.scheme__choice-item').on('click', function(e) {
        e.preventDefault();
        const targetId = $(this).data('scheme');
        const targetItem = $(this).data('scheme').replace(/[^A-Za-z]/g, "");
        const active = $(this).hasClass('scheme__choice-item_active');
        $(this).addClass('scheme__choice-item_active');
        $('.scheme__img_' + targetItem + ' .scheme__choice-item').not($(this)).removeClass('scheme__choice-item_active');
        $('.scheme__img_' + targetItem + ' .scheme__item').removeClass('scheme__item_active');
        $('.scheme__item[data-scheme="' + targetId + '"]').addClass('scheme__item_active');
    });

    // DESCRIPTION IMAGE
    $('.js-description-image-selector select').customSelect();
    $('.js-description-image-selector select').on('change', function() {
        var targetId = $(this).val();
        $(targetId).addClass('select__img_active').siblings().removeClass('select__img_active');
    }).change();

    // DESCRIPTION TABLE SCROLLER
    $('.js-description-table-scroller').mCustomScrollbar();

    // FANCYBOX
    $('[data-fancybox]').fancybox({
        lang: 'ru',
        i18n: {
            'ru': {
                CLOSE: 'Закрыть',
                NEXT: 'Следующий слайд',
                PREV: 'Предыдущий слайд',
                ERROR: 'Возникла ошибка при загрузке страницы.<br>Пожалуйста, попробуйте позже.',
                PLAY_START: 'Начать показ',
                PLAY_STOP: 'Приостановить показ',
                FULL_SCREEN: 'Полноэкранный режим',
                THUMBS: 'Превью'
            }
        }
    });

    // FEATURES ITEM
    $('.features__items').on('init reInit afterChange', function(event, slick, currentSlide, nextSlide) {
        let i = (currentSlide ? currentSlide : 0) + 1;
        $('#features_counter').html(`0${i} / 0${slick.slideCount}`);

    });

    $('.features__items').slick({
        arrows: false,
        mobileFirst: true,
        slidesToShow: 1,
        speed: 360,
        zIndex: 111,
        responsive: [{
            breakpoint: 1023,
            settings: 'unslick'
        }],
    });

    // PRODUCTS ITEMS
    //   $('.products__items').on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
    //     var i = (currentSlide ? currentSlide : 0) + 1;
    //     $('#products_counter').html(`0${i} / 0${slick.slideCount}`);
    //   });

    $('.products__items').slick({
        arrows: false,
        mobileFirst: true,
        slidesToShow: 1,
        speed: 360,
        zIndex: 111,
        responsive: [{
            breakpoint: 1023,
            settings: 'unslick'
        }]
    });

    // PRODUCT SINGLE
    $('.products__img').slick({
        arrows: true,
        nextArrow: '<svg class="products__next" xmlns="http://www.w3.org/2000/svg" width="15" height="28" viewBox="0 0 15 28"><path style="fill: #fff; fill-rule: evenodd;" d="M1507.3,2273.28a1,1,0,0,1,1.4,0l13.02,13.01a0.984,0.984,0,0,1,0,1.4l-13.02,13.01a1,1,0,0,1-.7.29,1.019,1.019,0,0,1-.7-0.29,0.99,0.99,0,0,1,0-1.39l12.33-12.32-12.33-12.32A0.99,0.99,0,0,1,1507.3,2273.28Z" transform="translate(-1507.03 -2273)"/></svg>',
        prevArrow: '<svg class="products__prev" xmlns="http://www.w3.org/2000/svg" width="15" height="28" viewBox="0 0 15 28"><path style="fill: #fff; fill-rule: evenodd;" d="M1021.7,2273.28a1,1,0,0,0-1.4,0l-13.02,13.01a0.984,0.984,0,0,0,0,1.4l13.02,13.01a1,1,0,0,0,.7.29,1.019,1.019,0,0,0,.7-0.29,0.99,0.99,0,0,0,0-1.39l-12.33-12.32,12.33-12.32A0.99,0.99,0,0,0,1021.7,2273.28Z" transform="translate(-1007 -2273)"/></svg>',
        slidesToShow: 1,
        speed: 360,
        zIndex: 111,
        swipe: false
    });

    // SLIDER CONTROLS
    $('.js-slider-next').on('click', function(e) {
        e.preventDefault();
        var targetId = $(this).attr('href');
        $(targetId).slick('slickNext');
    });

    $('.js-slider-prev').on('click', function(e) {
        e.preventDefault();
        var targetId = $(this).attr('href');
        $(targetId).slick('slickPrev');
    });

    // MEDIA SLIDER
    $('.gallery__slider').on('init reInit afterChange', function(event, slick, currentSlide, nextSlide) {
        var i = (currentSlide ? currentSlide : 0) + 1;
        $('#gallery_counter').html(`0${i} / 0${slick.slideCount}`);
    });
    $('.gallery__slider').slick({
        slidesToShow: 1,
        arrows: false,
        infinite: false,
        draggable: false,
        swipe: false,
        responsive: [{
            breakpoint: 1024,
            settings: {
                swipe: true,
                infinite: true
            }
        }]
    });
    $('.gallery__thumb-slide').on('click', function() {
        const currentSlide = $(this);
        $('.gallery__thumb-slide').removeClass('gallery__thumb-slide_active');
        currentSlide.addClass('gallery__thumb-slide_active');
        $('.gallery__slider').slick('slickGoTo', currentSlide.index())
    });

    // PACKAGE CHOICE
    $('.package__choice-item').on('click', function(e) {
        e.preventDefault();
        const targetId = $(this).data('scheme');
        const active = $(this).hasClass('package__choice-item_active');

        $(this).addClass('package__choice-item_active');
        $('.package__choice-item').not($(this)).removeClass('package__choice-item_active');

        $('.package__img').removeClass('package__img_active');
        $('.package__img[data-scheme="' + targetId + '"]').addClass('package__img_active');
    });

    $('.package__img-container').slick({
        mobileFirst: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        adaptiveHeight: true,
        nextArrow: '<svg class="package__next" xmlns="http://www.w3.org/2000/svg" width="15" height="28" viewBox="0 0 15 28"><path style="fill: #a6ed65; fill-rule: evenodd;" d="M1507.3,2273.28a1,1,0,0,1,1.4,0l13.02,13.01a0.984,0.984,0,0,1,0,1.4l-13.02,13.01a1,1,0,0,1-.7.29,1.019,1.019,0,0,1-.7-0.29,0.99,0.99,0,0,1,0-1.39l12.33-12.32-12.33-12.32A0.99,0.99,0,0,1,1507.3,2273.28Z" transform="translate(-1507.03 -2273)"/></svg>',
        prevArrow: '<svg class="package__prev" xmlns="http://www.w3.org/2000/svg" width="15" height="28" viewBox="0 0 15 28"><path style="fill: #a6ed65; fill-rule: evenodd;" d="M1021.7,2273.28a1,1,0,0,0-1.4,0l-13.02,13.01a0.984,0.984,0,0,0,0,1.4l13.02,13.01a1,1,0,0,0,.7.29,1.019,1.019,0,0,0,.7-0.29,0.99,0.99,0,0,0,0-1.39l-12.33-12.32,12.33-12.32A0.99,0.99,0,0,0,1021.7,2273.28Z" transform="translate(-1007 -2273)"/></svg>',
        responsive: [{
            breakpoint: 1024,
            settings: 'unslick'
        }]
    });
    svg4everybody();
});
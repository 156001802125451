!function (s) {
  s.fn.customSelect = function (e) {
    this.each(function () {
      var e = s(this), i = s('<div class="cs-box"></div>'), o = s('<div class="cs-box__toggle"></div>'),
        d = s('<div class="cs-box__dropdown"></div>'), a = s('<div class="cs-box__options"></ul>');
      i.append(o), e.is(":disabled") && i.addClass("is-disabled"), e.children("option").each(function () {
        var e = s('<div class="cs-box__option" data-val="' + s(this).val() + '">' + s(this).html() + "</div>");
        s(this).is(":disabled") && (e.addClass("is-disabled"), s(this).is(":selected") && i.children("div").addClass("is-placeholding")), a.append(e)
      }), d.append(a), i.append(d), e.after(i).hide();
      var t = 0;
      if (e.children("option:selected").length && (t = e.prop("selectedIndex")), o.html(a.children(".cs-box__option").eq(t).html()), a.children(".cs-box__option").eq(t).addClass("is-active"), e.children("option").eq(t).prop("selected", !0), o.on("click", function () {
        if (i.hasClass("is-disabled")) return !1;
        o.hasClass("is-active") ? (s(".cs-box").removeClass("is-focused"), s(".cs-box__toggle").removeClass("is-active"), s(".cs-box__dropdown").removeClass("is-opened")) : (s(".cs-box").removeClass("is-focused"), s(".cs-box__toggle").removeClass("is-active"), s(".cs-box__dropdown").removeClass("is-opened"), i.addClass("is-focused"), o.addClass("is-active"), d.addClass("is-opened"))
      }), a.children(".cs-box__option").on("click", function () {
        if (e.children("option").eq(s(this).index()).is(":disabled")) return !1;
        s(this).addClass("is-active").siblings(".cs-box__option").removeClass("is-active"), i.removeClass("is-focused"), o.html(s(this).html()).removeClass("is-active is-placeholding"), d.removeClass("is-opened"), e.children("option").eq(s(this).index()).prop("selected", !0), e.trigger("change")
      }), Boolean(e.data("filter"))) {
        var c = s('<div class="cs-box__filter"></div>'),
          l = s('<input type="text" placeholder="' + e.data("placeholder") + '">');
        c.append(l), d.prepend(c), l.on("input propertychange", function () {
          var e = s.trim(l.val().toLowerCase());
          "" != e ? a.children(".cs-box__option:not(.is-disabled)").each(function () {
            s(this).html().toLowerCase().indexOf(e) ? s(this).hide() : s(this).show()
          }) : a.children(".cs-box__option:not(.is-disabled)").each(function () {
            s(this).show()
          })
        })
      }
    }), s(document).on("click", function (e) {
      s(e.target).parents(".cs-box").length || (s(".cs-box").removeClass("is-focused"), s(".cs-box__toggle").removeClass("is-active"), s(".cs-box__dropdown").removeClass("is-opened"))
    })
  }
}(jQuery);